import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { gsap } from 'gsap/dist/gsap';
import useOnScreen from "../customHook/useOnScreen";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

export const FadeType = ({ children,repeat=false, wrapperElement = "div", type = null, delay = 0, distance = 100,stagger = 0.1, ...props }) => {

    const Component = wrapperElement;

    let fadeDirection;

    switch (type) {
        case "left":
            fadeDirection = { x: -distance };
            break;
        case "right":
            fadeDirection = { x: distance };
            break;
        case "up":
            fadeDirection = { y: distance };
            break;
        case "down":
            fadeDirection = { y: -distance };
            break;
        default:
            fadeDirection = { x: 0 };
    }

    // const ref = useRef(null);

    // useEffect(() => {

    //     gsap.set(ref.current, { ...fadeDirection, autoAlpha: 0 });
    //     gsap.utils.toArray(ref.current).forEach(function () {
    //         const animateFrom = gsap.fromTo(ref.current, { ...fadeDirection, autoAlpha: 0 }, {
    //             duration: 1.25,
    //             delay: delay,
    //             x: 0,
    //             y: 0,
    //             autoAlpha: 1,
    //             ease: "expo",
    //             overwrite: "auto",
    //             paused: true
    //         });

    //         ScrollTrigger.create({
    //             trigger: ref.current,
    //             // start: "top",
    //             once: true,
    //             onEnter: self => { self.progress === 1 ? animateFrom.progress(1) : animateFrom.play() }
    //         });
    //     });
    // }, []);

    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
        else if(reveal && repeat)
        {
            setReveal(false)
        }
    }, [onScreen]);

    useLayoutEffect(() => {
        gsap.set(ref.current, { autoAlpha: 0 });
        if (reveal) {
            gsap.fromTo(ref.current, { ...fadeDirection, autoAlpha: 0 },{
                duration: 1.25,
                delay: delay,
                x: 0,
                y: 0,
                autoAlpha: 1,
                stagger: stagger,
                ease: "power4.out",
            });
        }
    }, [reveal]);

    return (
        <Component ref={ref} {...props}>
            {children}
        </Component>
    );
};

export default FadeType;