import { useEffect, useState, Suspense, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Navbar from './components/Navbar'
import FooterWithContactUs from './components/FooterWithContactUs'
import Loader from './components/Loader'
import MobAppBar from './components/MobAppBar'
import useBreakpoints from './customHook/useBreakpoints';
import Lenis from '@studio-freight/lenis'
// import gsap from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'
// import { LocomotiveScrollProvider, useLocomotiveScroll } from "react-locomotive-scroll";
// import 'locomotive-scroll/dist/locomotive-scroll.css'
// import ScrollTriggerProxy from './customHook/ScrollTriggerProxy';

const ContactUs = lazy(() => import('./pages/ContactUs'));
const Courses = lazy(() => import('./pages/courses/Courses'));
const Residencies = lazy(() => import('./pages/courses/Residencies'));
const About = lazy(() => import('./pages/About'));
const Shop = lazy(() => import('./pages/Shop'));
const WorkShops = lazy(() => import('./pages/workshops'));
const WorkshopCourse = lazy(() => import('./pages/workshops/WorkshopCourse'));
const Stay = lazy(() => import('./pages/stay'));
const Architecture = lazy(() => import('./pages/Architecture.jsx'));
const WorkWithUs = lazy(() => import('./pages/WorkWithUs.jsx'));
const Press = lazy(() => import('./pages/Press'));
const Library = lazy(() => import('./pages/Library'));
const HomePreload = import('./pages/Home/Home');
const HomeTransitionPreload = import('./pages/Home/HomeTransition');
const Home = lazy(() => HomePreload);
const HomePageComp = lazy(() => import('./pages/Home/HomePageComp'));
const HomeTransition = lazy(() => HomeTransitionPreload);

const App = () => {

	const { isMd } = useBreakpoints();

	const [prevLocation, setPrevLocation] = useState("");

	const location = useLocation();

	const { pathname, hash, key } = useLocation();

	useEffect(() => {
		setPrevLocation(pathname)
	}, [pathname])

	useEffect(() => {
		const lenis = new Lenis({
			lerp: 0.1,
			smooth: true,
		});
		const scrollFn = () => {
			lenis.raf();
			requestAnimationFrame(scrollFn);
		};
		requestAnimationFrame(scrollFn);
		// if not a hash link, scroll to top
		if (!hash) {
			setTimeout(() => {
					lenis.scrollTo(0)
			}, 100);
		}
		// else scroll to id
		else {
			if (prevLocation === pathname) {
				setTimeout(() => {
					const element = document.querySelector(hash);
					if (element) {
						lenis.scrollTo(hash)
					}
				}, 100);
			}
			else {
				setTimeout(() => {
					const element = document.querySelector(hash);
					if (element) {
						lenis.scrollTo(hash)
					}
				}, 2100);
			}
		}
	}, [pathname, key, hash])


	const excludeFooter = ["/contactus", "/", "/home", "/home/explored", "/courses"];

	const excludeNavbar = ["/", "/home", "/home/explored"]

	return (
		<>
			<div>
				{!excludeNavbar.includes(location.pathname) && (isMd ? <MobAppBar /> : <Navbar />)}
				<Suspense>
					<Routes location={location} key={location.pathname}>
						<Route path='/about' element={<About />} />
						<Route path='/contactus' element={<ContactUs />} />
						<Route path='/courses' element={<Courses />} />
						<Route path='/craft/residencies' element={<Residencies purpose={true} />} />
						<Route path='/artist/residencies' element={<Residencies purpose={false} />} />
						<Route path='/shop' element={<Shop />} />
						<Route path='/workshops' element={<WorkShops />} />
						<Route path='/workshops/course' element={<WorkshopCourse />} />
						<Route path='/properties' element={<Stay purpose={true} />} />
						<Route path='/experience' element={<Stay purpose={false} />} />
						<Route path='/architecture' element={<Architecture />} />
						<Route path='/work_with_us' element={<WorkWithUs />} />
						<Route path='/press' element={<Press />} />
						<Route path='/library' element={<Library />} />

						<Route path='/' element={<HomeTransition />} />
						<Route path='/home/explored' element={<Home />} />
						<Route path='/home' element={<HomePageComp />} />
					</Routes>
					{!excludeFooter.includes(location.pathname) && <FooterWithContactUs />}
				</Suspense>
			</div>
		</>
	)
}

export default App