import { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import N from "../assets/N.png";
import styles from '../styles/componentStyles/appBar.module.css';
import { useNavigate } from 'react-router-dom';
import MobNavbar from './MobNavbar';
import useOnClickOutside from "use-onclickoutside";
import { useLocation } from 'react-router-dom';
import Lenis from '@studio-freight/lenis'

const MobAppBar = () => {

    const location = useLocation();

    const [showNavbar, setShowNavbar] = useState(false);

    const [showClick, setShowClick] = useState();
    const [showLocation, setShowLocation] = useState("");

    const navigate = useNavigate();
    const btnRef = useRef()

    const handleClick = (data) => {
        setShowClick(data)
    }

    const closeNav = () => {
        setShowNavbar(false)
    }

    const clickToTop = ()=> {
        const lenis = new Lenis({
			lerp: 0.1,
			smooth: true,
		});
        const scrollFn = () => {
			lenis.raf();
			requestAnimationFrame(scrollFn);
		};
		requestAnimationFrame(scrollFn);
        lenis.scrollTo(0,0)
    }

    useEffect(() => {
        gsap.fromTo("#show", { autoAlpha: 0, x: -70 },
            { autoAlpha: 1, x: 0, duration: 0.6 }
        );
    }, [showClick])

    useEffect(() => {
        let locationName = location.pathname
        if (locationName === "/workshops/course") {
            setShowLocation("/course")
        }
        else if (locationName === "/craft/residencies") {
            setShowLocation("/residencies")
        }
        else if (locationName === "/") {
            setShowLocation("/home")
        }
        else if (locationName === "/artist/residencies") {
            setShowLocation("/residencies")
        }
        else {
            setShowLocation(locationName);
        }
    }, [location])

    const spanRef = useRef();

    const [scrollDir, setScrollDir] = useState(true);

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? false : true);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useOnClickOutside(spanRef, () => setShowClick(""))

    return (
        <>
            <header id='headerNav' className={`${showNavbar && "menu_active"} ${scrollDir ? "upscroll" : "downscroll" }`}>
                <nav id='navbar'>
                    <div id='appbar' className={styles.appbar_container} ref={spanRef}>
                        <div id='logo' className={`${styles.logo} ${showClick === "logo" && styles.active}`} onClick={() => { handleClick("logo"); setTimeout(() => { navigate("/home") }, 2000); }}>
                            <div className={styles.n_logo}>
                                <img
                                    src={N}
                                    alt="logo"
                                />
                            </div>
                            {showClick === "logo" && <span id='show' className={styles.active_link}>ORTH</span>}
                        </div>
                        <p className={styles.location} onClick={clickToTop}>
                            {/* {showClick === "menu" && <span>- </span>}{showLocation} */}
                            {showLocation}
                        </p>
                        <div className={`${styles.navbar}`} onClick={() => handleClick("menu")}>
                            <div className={`${styles.navbar_cont_btn} ${showClick === "menu" && styles.active}`}>
                                <div className={styles.menu_btn_cont} onClick={() => setShowNavbar(!showNavbar)} ref={btnRef}>
                                    <div className={`${styles.menu_btn} ${showNavbar && styles.close_menu___btn}`}></div>
                                    <div className={`${styles.menu_btn} ${showNavbar && styles.close_menu___btn}`}></div>
                                    <div className={`${styles.menu_btn} ${showNavbar && styles.close_menu___btn}`}></div>
                                </div>
                                {showClick === "menu" && <span id='show' className={styles.active_link}>Menu</span>}
                                <MobNavbar show={showNavbar} closeFunc={closeNav} btnRef={btnRef} />
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default MobAppBar;