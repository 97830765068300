import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import styles from '../styles/componentStyles/MobNavbar.module.css';
import { BiChevronDown } from "react-icons/bi"
// import gsap from 'gsap';

const MobNavbar = ({ show, closeFunc, btnRef }) => {

    const [hideNav, setHideNav] = useState(true);
    // const [showNavbar, setShowNavbar] = useState(false);
    const [hideDisplayNav, setHideDisplayNav] = useState(true);

    const navRef = useRef()
    // const btnRef = useRef()

    const [showDropdownId, setShowDropdownId] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [btnHover, setBtnHover] = useState(false);

    const closeNav = () => {
        closeFunc()
        // setShowNavbar(false)
    }

    const handleDropdownClose = () => {
        let timeout = setTimeout(() => {
            setShowDropdownId("")
        }, 1000);

        return () => { clearTimeout(timeout); }
    }

    function handleClick(event) {
        if (navRef.current.contains(event.target) || btnRef.current.contains(event.target)) {
            // inside click
            return;
        }
        closeFunc();
        // setShowNavbar(false);
    }

    useEffect(() => {
        // only add the event listener when the sidebar is opened
        if (!show) return;
        if (show)
            window.addEventListener("click", handleClick);
        // clean up
        return () => window.removeEventListener("click", handleClick);
    }, [show]);

    useEffect(() => {
        let timeoutId;

        if (show) {
            document.body.style.overflow = "hidden";
            setHideDisplayNav(false)
            timeoutId = setTimeout(() => { setHideNav(false) }, 100);
        }
        else {
            document.body.style.overflowY = "scroll";
            setHideNav(true)
            timeoutId = setTimeout(() => { setHideDisplayNav(true) }, 200);
            setBtnHover(false)
            setShowDropdown(false)
            setShowDropdownId("")
        }

        return () => {
            clearTimeout(timeoutId);
        }
    }, [show])

    useEffect(() => {
        setShowDropdown(true)
        setBtnHover(true)
    }, [showDropdownId])

    const dropdownBtnFunc = (data)=> {
        setShowDropdownId(data);
        setBtnHover(!btnHover);
        setShowDropdown(!showDropdown)
    }

    return (
        <div className={styles.nav_container}>
            <div className={`${styles.nav_background} ${!hideNav && styles.nav_active}`}></div>
            <div className={`${styles.nav_menu} ${hideNav && styles.nav_menu___hide_animation} ${hideDisplayNav && styles.nav_menu___hide}`}>
                <div ref={navRef}>
                    <div className={styles.nav_menu___flex_grid}>
                        <ul>
                            <li>
                                <span>
                                    <div className={styles.nav_link}>
                                        <Link to={"/about"} onClick={closeNav} className={showDropdownId === "about" && showDropdown ? styles.drop_link_active : undefined}>
                                            About
                                        </Link>
                                        <button onClick={()=>dropdownBtnFunc("about")}>
                                            <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "about" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                        </button>
                                    </div>
                                </span>
                                <div className={`${styles.nav_dropdown} ${showDropdownId === "about" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                    <div className={styles.sub_dropdown}>
                                        <Link onClick={closeNav} to="/about#vision">
                                            Our Vision
                                        </Link>
                                        <Link onClick={closeNav} to="/about#journey">
                                            Our Journey
                                        </Link>
                                        <Link onClick={closeNav} to="/about#team">
                                            Team
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <span>
                                    <div className={styles.nav_link}>
                                        <Link to={"/architecture"} onClick={closeNav} className={showDropdownId === "architecture" && showDropdown ? styles.drop_link_active : undefined}>
                                            Architecture
                                        </Link>
                                        <button onClick={()=>dropdownBtnFunc("architecture")}>
                                            <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "architecture" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                        </button>
                                    </div>
                                </span>
                                <div className={`${styles.nav_dropdown} ${showDropdownId === "architecture" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                    <div className={styles.sub_dropdown}>
                                        <Link onClick={closeNav} to="/architecture#archprojects">
                                            Our Studio/Projects
                                        </Link>
                                        <Link onClick={closeNav} to="/architecture#researchlab">
                                            Research Lab
                                        </Link>
                                        <Link onClick={closeNav} to="/library">
                                            Library
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <span>
                                    <div className={styles.nav_link}>
                                        <Link to={"/properties"} onClick={closeNav} className={showDropdownId === "stay" && showDropdown ? styles.drop_link_active : undefined}>
                                            Stay
                                        </Link>
                                        <button onClick={()=>dropdownBtnFunc("stay")}>
                                            <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "stay" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                        </button>
                                    </div>
                                </span>
                                <div className={`${styles.nav_dropdown} ${showDropdownId === "stay" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                    <div className={styles.dropdown}>
                                        <div>
                                            <Link onClick={closeNav} to="/properties">
                                                Properties
                                            </Link>
                                            <div className={styles.sub_dropdown}>
                                                <Link onClick={closeNav} to="/properties#cabinnorth">
                                                    Cabins by North
                                                </Link>
                                                <Link onClick={closeNav} to="/properties#northestate">
                                                    North Estate
                                                </Link>
                                            </div>
                                        </div>
                                        <hr className={styles.nav_menu___line_horizonetal} />
                                        <Link onClick={closeNav} to="/experience">
                                            Experience
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <span>
                                    <div className={styles.nav_link}>
                                        <Link to={"/courses"} onClick={closeNav} className={showDropdownId === "course" && showDropdown ? styles.drop_link_active : undefined}>
                                            Courses
                                        </Link>
                                        <button onClick={()=>dropdownBtnFunc("course")}>
                                            <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "course" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                        </button>
                                    </div>
                                </span>
                                <div className={`${styles.nav_dropdown} ${showDropdownId === "course" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                    <div className={styles.dropdown}>
                                        <Link onClick={closeNav} to="/workshops">
                                            Workshops
                                        </Link>
                                        <hr className={styles.nav_menu___line_horizonetal} />
                                        <div>
                                            <Link onClick={closeNav} to="/craft/residencies">
                                                Residencies
                                            </Link>
                                            <div className={styles.sub_dropdown}>
                                                <Link onClick={closeNav} to="/craft/residencies">
                                                    Craft Residencies
                                                </Link>
                                                <Link onClick={closeNav} to="/artist/residencies">
                                                    Artist Residencies
                                                </Link>
                                            </div>
                                        </div>
                                        <hr className={styles.nav_menu___line_horizonetal} />
                                        <Link onClick={closeNav} to="/work_with_us">
                                            Work With Us
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <hr className={styles.nav_menu___line_horizonetal} />
                            <div className={styles.nav_menu___inline_links}>
                                <li>
                                    <Link onClick={closeNav} to="/shop" onMouseOver={() => handleDropdownClose()}>
                                        Shop
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={closeNav} to="/contactus" onMouseOver={() => handleDropdownClose()}>
                                        Contact
                                    </Link>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.nav_menu___footer}>
                        <Link onClick={closeFunc} to={"/library"}>Library</Link>
                        <h4>©thenorth.in</h4>
                        <Link onClick={closeFunc} to={"/press"}>Press</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobNavbar;