import React from "react";
import styles from "../styles/componentStyles/FooterWithContactUs.module.css";
import { FadeType } from "../animation/Animation"
import { Link } from "react-router-dom";
import gsap from "gsap";

const FooterWithContactUs = () => {

	const signHover = () => {
		// const pathTo = document.getElementById("footer_arrow").dataset.pathTo;
		gsap.to("#footer_arrow", { attr: { d: "M20,10 l15,15-15,15" }, ease: 'none', duration: 0.1 })
	}
	const signUnHover = () => {
		// const pathTo = document.getElementById("footer_arrow").dataset.pathTo;
		gsap.to("#footer_arrow", { attr: { d: "M20,10 l0,15-0,15" }, ease: 'none', duration: 0.1 })
	}

	return (
		<div className={styles.footer_container}>
			<FadeType type="left">
				{/* <Link to={"/contactus"} className={styles.footer_contact}> */}
				<Link to={"/contactus"} className={styles.footer_contact} onMouseEnter={signHover} onMouseLeave={signUnHover}>
					<h1>Contact Us</h1>
					<div className={styles.footer_sign}>
						{/* <div className={styles.sign}></div> */}
						<svg
							id='footer_sign'
							viewBox="0 0 40 40"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							preserveAspectRatio="none"
						>
							<path id="footer_arrow" d="M20,10 l0,30" data-path-to="M20,10.222195l15,15-15,15" transform="translate(0-5.222195)" fill="none" stroke="#fff" strokeWidth="4" />
							<path d="M5.970951,20h30" transform="translate(-.970951 0)" fill="none" stroke="#fff" strokeWidth="4" />
						</svg>
					</div>
					{/* </div> */}
				</Link>
			</FadeType>
			<div className={styles.footer_middle}>
				<div className={styles.footer_column_1}>
					<h3>©thenorth.in</h3>
				</div>
				{/* <div className={styles.footer_column_2}>
					<Link to={"/library"}>
						<h3>Library</h3>
					</Link>
					<Link to={"/press"}>
						<h3>Press</h3>
					</Link>
					<div className={styles.social_link}>
						<a href="https://www.linkedin.com/company/thenorth" target="_blank" rel="noopener noreferrer">
							Linked In
						</a>
					</div>
				</div>
				<div className={styles.footer_column_3}>
					<Link to={"/contactus"}>
						<h3>Contact Us</h3>
					</Link>
					<div className={styles.social_link}>
						<a href="https://www.facebook.com/livetheNorth" target="_blank" rel="noopener noreferrer">
							Facebook
						</a>
						<a href="https://www.instagram.com/live_north/" target="_blank" rel="noopener noreferrer">
							Instagram
						</a>
					</div>
				</div> */}
				<div className={styles.footer_column_5}>
					<div className={styles.footer_link_columns}>
						<Link to={"/contactus"}>
							<h3>Contact Us</h3>
						</Link>
						<div className={styles.social_link}>
							<a href="https://www.instagram.com/live_north/" target="_blank" rel="noopener noreferrer">
								Instagram
							</a>
						</div>
					</div>
					<div className={styles.footer_link_columns}>
						<Link to={"/library"}>
							<h3>Library</h3>
						</Link>
						<div className={styles.social_link}>
							<a href="https://www.facebook.com/livetheNorth" target="_blank" rel="noopener noreferrer">
								Facebook
							</a>
						</div>
					</div>
					<div className={styles.footer_link_columns}>
						<Link to={"/press"}>
							<h3>Press</h3>
						</Link>
						<div className={styles.social_link}>
							<a href="https://www.linkedin.com/company/thenorth" target="_blank" rel="noopener noreferrer">
								Linked In
							</a>
						</div>
					</div>
				</div>
				{/* <div className={styles.footer_link_column}>
					<div className={styles.footer_link_row}>
						<Link to={"/library"}>
							<h3>Library</h3>
						</Link>
						<Link to={"/press"}>
							<h3>Press</h3>
						</Link>
						<Link to={"/contactus"}>
							<h3>Contact Us</h3>
						</Link>
					</div>
					<div className={styles.social_link}>
						<a href="https://www.linkedin.com/company/thenorth" target="_blank" rel="noopener noreferrer">
							Linked In
						</a>

						<a href="https://www.facebook.com/livetheNorth" target="_blank" rel="noopener noreferrer">
							Facebook
						</a>
						<a href="https://www.instagram.com/live_north/" target="_blank" rel="noopener noreferrer">
							Instagram
						</a>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default FooterWithContactUs;
