import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import styles from '../styles/componentStyles/navbar.module.css';
import N from "../assets/N.png";
// import ORTH from "../assets/orth.png";
import useBreakpoints from "../customHook/useBreakpoints"
import { BiChevronDown } from "react-icons/bi"

// const navMenuList = [
//     {
//         headLink: "About",
//         dropdownmenu: [
//             "Our Journey",
//             "Team",
//             "Vision",
//         ]
//     },
//     {
//         headLink: "Architecture",
//         dropdownmenu: [
//             "Our Studio/Projects",
//             "Research Lab",
//             "Library",
//         ]
//     },
//     {
//         headLink: "Stay",
//         dropdownmenu: [
//             {
//                 dropdownHead: "Properties",
//                 subDropdown: [
//                     "Cabins by North",
//                     "North Estates",
//                 ]
//             },
//             "Experiences",
//         ],
//     },
//     {
//         headLink: "Courses",
//         dropdownmenu: [
//             "Workshops",
//             {
//                 dropdownHead: "Residencies",
//                 subDropdown: [
//                     "Craft Residencies",
//                     "Artist Residencies",
//                 ]
//             },
//             "Work With Us",
//         ],
//     },
//     {
//         headLink: "Shop",
//     },
//     {
//         headLink: "Contact",
//     },
// ]

const Navbar = () => {
    // gsap.registerPlugin(ScrollTrigger);

    const [showSidebar, setShowSidebar] = useState(false);
    const [hideNav, setHideNav] = useState(true);
    const [hideDisplayNav, setHideDisplayNav] = useState(true);

    const { isXl } = useBreakpoints();

    const { pathname, hash, key } = useLocation();

    const [btnHover, setBtnHover] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownId, setShowDropdownId] = useState("");

    const closeNav = () => {
        setShowSidebar(false)
    }

    const handleDropdownClose = () => {
        let timeout = setTimeout(() => {
            setShowDropdown(false)
        }, 1000);

        return () => { clearTimeout(timeout); }
    }

    // const logoNRef = useRef()
    // const logoOrthRef = useRef()
    const navRef = useRef()

    useEffect(() => {
        let timeoutId;

        // gsap.fromTo(logoNRef.current, { autoAlpha: 1, x: 0 },
        //     { autoAlpha: 1, x: 70, duration: 0.6 }
        // );

        // gsap.fromTo(logoOrthRef.current, { autoAlpha: 1, x: 0 },
        //     { autoAlpha: 0, x: 70, duration: 0.6 }
        // );

        if (showSidebar) {
            document.body.style.overflow = "hidden";
            setHideDisplayNav(false)
            timeoutId = setTimeout(() => { setHideNav(false) }, 100);
        }
        else {
            document.body.style.overflowY = "scroll";
            setHideNav(true)
            timeoutId = setTimeout(() => { setHideDisplayNav(true) }, 200);
            setBtnHover(false)
            setShowDropdown(false)
            setShowDropdownId("")
        }

        return () => {
            clearTimeout(timeoutId);
        }
    }, [showSidebar])

    function handleClick(event) {
        if (navRef.current.contains(event.target)) {
            // inside click
            return;
        }
        // closeFunc();
        setShowSidebar(false);
    }

    useEffect(() => {
        // only add the event listener when the sidebar is opened
        if (isXl) {
            if (!showSidebar) return;
            if (showSidebar)
                window.addEventListener("click", handleClick);
            // clean up
            return () => window.removeEventListener("click", handleClick);
        }
    }, [showSidebar, isXl]);

    useEffect(() => {
        setShowDropdown(true)
        setBtnHover(true)
    }, [showDropdownId])

    const dropdownBtnFunc = (data)=> {
        setShowDropdownId(data);
        setBtnHover(!btnHover);
        setShowDropdown(!showDropdown)
    }

    return (
        <header>
            <nav>
                <div className={styles.nav_header}>
                    {showSidebar === true &&
                        <Link to="/home" onClick={closeNav}>
                            <img
                                src={N}
                                alt="logo"
                                className={styles.n_logo}
                            />
                            {/* <img
                                ref={logoOrthRef}
                                src={ORTH}
                                alt="logo"
                                className={styles.orth_logo}
                                loading="lazy"
                            /> */}
                        </Link>
                    }
                </div>
                <div ref={navRef} className={styles.nav_container}>
                    <div className={`btn_nav ${styles.nav_menu___btn} ${showSidebar && styles.btn_close}`} onClick={() => setShowSidebar(!showSidebar)}>
                        <div></div>
                    </div>
                    <div className={`${styles.nav_background} ${!hideNav && styles.nav_active}`}></div>
                    <div className={`${styles.nav_menu} ${hideNav && styles.nav_menu___hide_animation} ${hideDisplayNav && styles.nav_menu___hide}`}>
                        <div className={styles.nav_menu___flex_grid}>
                            <ul>
                                {isXl ?
                                    <>
                                        <li>
                                            <span>
                                                <div className={styles.nav_link}>
                                                    <Link to={"/about"} onClick={closeNav} className={showDropdownId === "about" && showDropdown ? styles.drop_link_active : undefined}>
                                                        About
                                                    </Link>
                                                    <button onClick={()=>dropdownBtnFunc("about")}>
                                                        <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "about" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                                    </button>
                                                </div>
                                            </span>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "about" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                                <div className={styles.sub_dropdown}>
                                                    <Link onClick={closeNav} to="/about#vision">
                                                        Our Vision
                                                    </Link>
                                                    <Link onClick={closeNav} to="/about#journey">
                                                        Our Journey
                                                    </Link>
                                                    <Link onClick={closeNav} to="/about#team">
                                                        Team
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <span>
                                                <div className={styles.nav_link}>
                                                    <Link to={"/architecture"} onClick={closeNav} className={showDropdownId === "architecture" && showDropdown ? styles.drop_link_active : undefined}>
                                                        Architecture
                                                    </Link>
                                                    <button onClick={()=>dropdownBtnFunc("architecture")}>
                                                        <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "architecture" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                                    </button>
                                                </div>
                                            </span>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "architecture" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                                <div className={styles.sub_dropdown}>
                                                    <Link onClick={closeNav} to="/architecture#archprojects">
                                                        Our Studio/Projects
                                                    </Link>
                                                    <Link onClick={closeNav} to="/architecture#researchlab">
                                                        Research Lab
                                                    </Link>
                                                    <Link onClick={closeNav} to="/library">
                                                        Library
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <span>
                                                <div className={styles.nav_link}>
                                                    <Link to={"/properties"} onClick={closeNav} className={showDropdownId === "stay" && showDropdown ? styles.drop_link_active : undefined}>
                                                        Stay
                                                    </Link>
                                                    <button onClick={()=>dropdownBtnFunc("stay")}>
                                                        <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "stay" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                                    </button>
                                                </div>
                                            </span>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "stay" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                                <div className={styles.dropdown}>
                                                    <Link onClick={closeNav} to="/properties">
                                                        Properties
                                                    </Link>
                                                    <div className={styles.sub_dropdown}>
                                                        <Link onClick={closeNav} to="/properties#cabinnorth">
                                                            Cabins by North
                                                        </Link>
                                                        <Link onClick={closeNav} to="/properties#northestate">
                                                            North Estate
                                                        </Link>
                                                    </div>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <Link onClick={closeNav} to="/experience">
                                                        Experience
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <span>
                                                <div className={styles.nav_link}>
                                                    <Link to={"/courses"} onClick={closeNav} className={showDropdownId === "course" && showDropdown ? styles.drop_link_active : undefined}>
                                                        Courses
                                                    </Link>
                                                    <button onClick={()=>dropdownBtnFunc("course")}>
                                                        <BiChevronDown className={`${styles.nav_arrow} ${showDropdownId === "course" && btnHover ? styles.btn_hovered : styles.btn_un_hovered}`} />
                                                    </button>
                                                </div>
                                            </span>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "course" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`}>
                                                <div className={styles.dropdown}>
                                                    <Link onClick={closeNav} to="/workshops">
                                                        Workshops
                                                    </Link>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <Link onClick={closeNav} to="/craft/residencies">
                                                        Residencies
                                                    </Link>
                                                    <div className={styles.sub_dropdown}>
                                                        <Link onClick={closeNav} to="/craft/residencies">
                                                            Craft Residencies
                                                        </Link>
                                                        <Link onClick={closeNav} to="/artist/residencies">
                                                            Artist Residencies
                                                        </Link>
                                                    </div>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <Link onClick={closeNav} to="/work_with_us">
                                                        Work With Us
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li>
                                            <Link to={"/about"} onClick={closeNav} className={showDropdownId === "about" && showDropdown ? styles.drop_link_active : undefined} onMouseEnter={() => { setShowDropdownId("about"); setShowDropdown(true) }}>
                                                About
                                            </Link>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "about" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`} onMouseEnter={() => { setShowDropdownId("about"); setShowDropdown(true) }} onMouseLeave={() => handleDropdownClose()}>
                                                <div className={styles.sub_dropdown}>
                                                    <Link onClick={closeNav} to="/about#vision">
                                                        Our Vision
                                                    </Link>
                                                    <Link onClick={closeNav} to="/about#journey">
                                                        Our Journey
                                                    </Link>
                                                    <Link onClick={closeNav} to="/about#team">
                                                        Team
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <Link to={"/architecture"} onClick={closeNav} className={showDropdownId === "architecture" && showDropdown ? styles.drop_link_active : undefined} onMouseEnter={() => { setShowDropdownId("architecture"); setShowDropdown(true) }}>
                                                Architecture
                                            </Link>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "architecture" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`} onMouseEnter={() => { setShowDropdownId("architecture"); setShowDropdown(true) }} onMouseLeave={() => handleDropdownClose()}>
                                                <div className={styles.sub_dropdown}>
                                                    <Link onClick={closeNav} to="/architecture#archprojects">
                                                        Our Studio/Projects
                                                    </Link>
                                                    <Link onClick={closeNav} to="/architecture#researchlab">
                                                        Research Lab
                                                    </Link>
                                                    <Link onClick={closeNav} to="/library">
                                                        Library
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <Link to={"/properties"} onClick={closeNav} className={showDropdownId === "stay" && showDropdown ? styles.drop_link_active : undefined} onMouseEnter={() => { setShowDropdownId("stay"); setShowDropdown(true) }}>
                                                Stay
                                            </Link>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "stay" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`} onMouseEnter={() => { setShowDropdownId("stay"); setShowDropdown(true) }} onMouseLeave={() => handleDropdownClose()}>
                                                <div className={styles.dropdown}>
                                                    <div>
                                                        <Link onClick={closeNav} to="/properties">
                                                            Properties
                                                        </Link>
                                                        <div className={styles.sub_dropdown}>
                                                            <Link onClick={closeNav} to="/properties#cabinnorth">
                                                                Cabins by North
                                                            </Link>
                                                            <Link onClick={closeNav} to="/properties#northestate">
                                                                North Estate
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <Link onClick={closeNav} to="/experience">
                                                        Experience
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <Link to={"/courses"} onClick={closeNav} className={showDropdownId === "course" && showDropdown ? styles.drop_link_active : undefined} onMouseEnter={() => { setShowDropdownId("course"); setShowDropdown(true) }}>
                                                Courses
                                            </Link>
                                            <div className={`${styles.nav_dropdown} ${showDropdownId === "course" && showDropdown ? styles.dropdown_show : styles.dropdown_hide}`} onMouseEnter={() => { setShowDropdownId("course"); setShowDropdown(true) }} onMouseLeave={() => handleDropdownClose()}>
                                                <div className={styles.dropdown}>
                                                    <Link onClick={closeNav} to="/workshops">
                                                        Workshops
                                                    </Link>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <div>
                                                        <Link onClick={closeNav} to="/craft/residencies">
                                                            Residencies
                                                        </Link>
                                                        <div className={styles.sub_dropdown}>
                                                            <Link onClick={closeNav} to="/craft/residencies">
                                                                Craft Residencies
                                                            </Link>
                                                            <Link onClick={closeNav} to="/artist/residencies">
                                                                Artist Residencies
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <hr className={styles.nav_menu___line_horizonetal} />
                                                    <Link onClick={closeNav} to="/work_with_us">
                                                        Work With Us
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                        <hr className={styles.nav_menu___line_vertical} />
                                    </>
                                }
                                <hr className={styles.nav_menu___line_horizonetal} />
                                <div className={styles.nav_menu___inline_links}>
                                    <li>
                                        <Link onClick={closeNav} to="/shop" onMouseOver={() => handleDropdownClose()}>
                                            Shop
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={closeNav} to="/contactus" onMouseOver={() => handleDropdownClose()}>
                                            Contact
                                        </Link>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div className={styles.nav_menu___footer}>
                            <div>
                                <Link onClick={closeNav} to={"/library"}>Library</Link>
                                <Link onClick={closeNav} to={"/press"}>Press</Link>
                            </div>
                            <h4>©thenorth.in</h4>
                        </div>
                    </div>
                </div>
            </nav>
        </header >
    );
};

export default Navbar;